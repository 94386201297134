.main-header {position: absolute;width: 100%;z-index: 99;top: 0;left: 0;}
.main-header .navbar {padding: 14px 0px;}
.main-header .navbar-brand {display: inline-block;max-width: 86px;}
.main-header .nav-item {margin-right: 101px;}
.main-header .nav-link {color: #fff;font-size: 16px;line-height: 20px;transition: all ease-in-out 0.3s;}
.main-header .nav-link:hover{color: #50FB56;}
.main-header .navbar-nav .nav-link.active,
.main-header .navbar-nav .nav-link.show{color: #fff;}
.main-header .navbar-collapse a{margin-right: 101px;}
.main-header .dropdown-menu {background: #50FB56 0% 0% no-repeat padding-box;border-radius: 10px;padding: 0;overflow: hidden}
.main-header .dropdown-item:focus, .main-header .dropdown-item:hover, .main-header .dropdown-item:active {background-color: #000;color: #fff;}
/* responsive */
@media only screen and (max-width:1199px){
    .main-header .navbar-collapse a{margin-right: 60px;}
    .main-header .nav-item {margin-right: 60px;}
}
@media only screen and (max-width:991px){
    .main-header .flex-div {flex-direction: row-reverse;}
    .main-header .navbar-toggler-icon{background-image: url("../../../assets/images/bars.svg");}
    .main-header .navbar-collapse {position: absolute;left: 0;right: 0;top: 100%;padding: 30px;background: #50FB56}
    .main-header .navbar-collapse a:not(:last-child){margin-right: 0px;margin-bottom: 25px;}
    /* .main-header .nav-item {margin-right: 0px;margin-bottom: 25px;} */
    .main-header .nav-link {color: #000;}
    /**/
    .main-header .navbar-toggler {background-color: transparent;padding: 0;margin-left: 10px;}
    .main-header .navbar-toggler:focus{box-shadow: none;}
    /**/
    .main-header .nav-link:hover {color: #000;font-weight: bold;transition: all ease-in-out 0.3s;}
}
@media only screen and (max-width:479px){
    .main-header .main-button {font-size: 10px;}
}
@media only screen and (max-width:319px){
    .main-header .navbar-brand {margin-right: 5px;}
    .main-header .navbar-toggler {margin-left: 5px;}
    .main-header .navbar-toggler-icon {width: 23px;}
}