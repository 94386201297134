.theme-form{background-image: url('./../../../assets/images/FormsBG.png'); background-repeat: no-repeat; background-size: cover;  width:100%; padding: 0px 0px 00px 0px;min-height: 100vh;}
.form-overlay{background-color: rgb(13 15 23 / 90%); height: 100%; width: 100%; position: fixed; top: 0px; }
.formContainer{padding: 0px 40px; display: flex; flex-direction: column; justify-content: center;}
.formLogo{padding: 30px 0px 5px 0px;}
.formLogo > a {display: inline-block;width: 100px;}
.formLogo img{width: 121px;}
.formGroup{margin-bottom: 10px;}
.biiview-form{position: relative; z-index: 999;}
.formStyle{min-width: 600px; background: #1B1D27; border-radius: 30px; padding: 50px 60px;  text-align: center;margin: auto; }
.wrapForm{min-height: calc(100vh - 90px); margin: auto;  display: flex;  align-items: center;  flex-direction: column;}
.card-details .wrapForm {width: 500px;flex-direction:initial}
.card-details .orange-btn {margin: 0 auto;}
.form-control{margin-bottom: 0px;}
.form-label, .form-control{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; margin-bottom: 10px;text-align: left;width: 100%;}
.form-control { background: #393C48 !important; border-radius: 10px; border: 1px solid transparent; height: 60px; color: #fff;}
.form-control:focus { background: #393C48 !important; border: 1px solid transparent; color: #fff;}
.inputDiv{ background: #393C48 !important;border-radius: 10px; border: 1px solid transparent; height: 62px; color: #fff; display: flex; align-items: center;padding-right: 20px;}
.inputDiv input{background: transparent !important;margin: 0px; color: #fff; border: 1px solid transparent;}
.inputDiv svg{color: #50fb56;}
.inputDiv:hover{ outline: rgba(80, 251, 86, 0.7); border-color: rgba(80, 251, 86, 0.7);}
.inputDiv input.form-control:focus, .inputDiv input.form-control:focus-visible {box-shadow: none; outline: transparent; border-color: transparent; border: 0px;color: #fff;}
.form-control:focus, .form-control:focus-visible {box-shadow: none; outline: rgba(80, 251, 86, 0.7); border-color: rgba(80, 251, 86, 0.7)}
.formStyle h2{font-family: 'outfit'; font-size: 30px; line-height: 34px; font-weight: 600; margin-bottom: 10px;}
.theme-form p{font-family: 'outfit'; font-size: 16px; line-height: 20px; font-weight: 400; color: #D0BBB6;}
.theme-form .outer-p{color: #fff;}
.formHeader{margin-bottom: 30px; padding-bottom: 30px; border-bottom: 1px solid #393C48;}
.formStyle .orange-btn{margin-top: 30px;min-width: 205px;}
.theme-capcha {padding-top: 10px; display: flex; flex-direction: column; align-items: center;}
.theme-capcha > div {margin-bottom: 8px;}
/**/
.page-link .outer-p:hover {color: #50fb56;}
/**/
/* .theme-capcha .captchaStyle{border-radius: 12px; height: 80px; width: 360px; overflow: hidden; background-color: #fff;}
.theme-capcha .captchaStyle div {margin:auto;} */
.end-mb{margin-bottom: 40px;}
.forgot{max-width: 480px;}
/* PACKAGE */
.formStyle.package{max-width: 1742px;}
.formGroup .text-danger{display: block; text-align: left; font-size: 12px; line-height: 12px;}
.troubbleText{max-width: 460px;}
/**/
.biiview-form .wrapForm {padding: 50px 0px 70px;}

/* MEDIA QUERY */

@media (max-width: 1699px) {
.formContainer{padding: 0px 35px;}
.formLogo{padding: 25px 0px 40px 0px;}
.formGroup{margin-bottom: 10px;}
.formStyle{min-width: 450px; border-radius: 15px; padding: 28px 30px;}
.form-label, .form-control{font-size: 12px; line-height: 18px; margin-bottom: 8px;}
.form-control {border-radius: 5px;height: 40px; }
.inputDiv{height: 42px;padding-right: 10px;border-radius: 5px;}
.formStyle h2{font-size: 25px; line-height: 30px; margin-bottom: 8px;}
.theme-form p{font-size: 12px; line-height: 18px;}
.formHeader{margin-bottom: 30px;}
.formStyle .orange-btn{margin-top: 20px;}
.end-mb{margin-bottom: 30px;}
.theme-capcha {padding-top: 8px;}
}
@media (max-width: 1520px) {
   .formStyle .orange-btn {min-width: 175px;}
}
@media (max-width: 1399px) {
.formHeader{padding-bottom: 20px;}
.formHeader { margin-bottom: 20px;}
.formLogo{padding: 15px 0px 20px 0px;}
.forgot{max-width: 380px;}
.biiview-form .wrapForm {padding: 30px 0px 30px;}
.formLogo>a {width: 80px;}
}
@media (max-width: 767px) {
.wrapForm{width: 100%;}
.formStyle{min-width: 80%; border-radius: 15px; padding: 28px 30px; }
.formContainer{padding: 0px 25px;}
.formLogo{padding: 15px 0px 20px 0px;}
 }
 @media (max-width: 479px) {
    .formStyle {padding: 28px 16px;}
    .theme-capcha > div {width: 100%;overflow-y: hidden;overflow-x: auto;}
    .theme-capcha > div iframe {width: 100%;}
    .formStyle h2 {font-size: 20px;line-height: 28px;}
    .formContainer {padding: 0px 12px;}
 }
