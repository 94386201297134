.get-started {margin: 0 0 37px;}
.get-started .started-box {padding: 78px 178px 73px 81px;background: #50FB56 0% 0% no-repeat padding-box;border-radius: 16px;display: flex;align-items: center;justify-content: space-between;}
.get-started .started-lottie {width: 212px;height: 212px;}
.get-started .started-data {flex: 1 1;color: #000;max-width: 530px;}
.get-started .started-data h4{margin: 0 0 14px;}
.get-started .started-data p{margin: 0 0 25px;}
/* responsive */
@media only screen and (max-width:1199px){
    .get-started .started-box {padding: 60px 70px 60px 30px;}
}
@media only screen and (max-width:767px){
    .get-started .started-box {flex-direction: column;}
    .get-started .started-data {margin: 0 0 30px;}
}
@media only screen and (max-width:479px){
    .get-started .started-box {padding: 60px 12px 60px 12px;}
}
