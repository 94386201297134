@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Black.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Outfit-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Bold.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Outfit-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Medium.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Regular.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Light.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Outfit-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "outfit";
  src: url("./assets/fonts/Outfit-Thin.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "SofiaPro";
  src: url("./assets/fonts/SofiaProBold.woff2") format("woff2"),
    url("./assets/fonts/SofiaProBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SofiaPro";
  src: url("./assets/fonts/SofiaProLight.woff2") format("woff2"),
    url("./assets/fonts/SofiaProLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
body {
  background: #0d0f17;
  font-family: "outfit";
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  color: #fff;
  overflow-x: hidden;
}
/* Scroll Bar Styles */
* {
  scrollbar-width: thin;
  scrollbar-color: #50fb56 #0d0f17;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #0d0f17;
}
::-webkit-scrollbar-thumb {
  background-color: #50fb56;
}
/**/
/*table responsive*/
.table-responsive::-webkit-scrollbar {background-color: #0d0f17; height: 8px; }
.table-responsive::-webkit-scrollbar-button { background-color: #0d0f17; height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-track  { background-color:#0d0f17; }
.table-responsive::-webkit-scrollbar-track-piece  { background-color: #0d0f17;}
.table-responsive::-webkit-scrollbar-thumb  {  background: #393c4b; }
.table-responsive::-webkit-scrollbar-corner { background-color:#0d0f17;  }
.table-responsive::-webkit-resizer {background-color:#0d0f17; }
.table-responsive{scrollbar-color:  #393c4b #0d0f17 ;scrollbar-width: thin;}
.table-responsive::-webkit-scrollbar-button:single-button {background-color: #0d0f17;display: block;border-style: solid;height: 8px;width: 10px;}
.table-responsive::-webkit-scrollbar-button:horizontal:start{border-width: 4px 4px 4px 0px;border-color: transparent #393c4b transparent transparent;}
.table-responsive::-webkit-scrollbar-button:horizontal:end{border-width: 4px 0px 4px 4px;border-color: transparent transparent transparent #393c4b;}
.table-responsive{scrollbar-color: #393c4b #0d0f17  !important; scrollbar-width: thin;}
/**/
.form-select:focus {
  box-shadow: none;
  outline: none;
}
select option {
  background-color: rgba(250, 250, 250, 0.3);
  background: rgba(250, 250, 250, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  padding: 5px 0;
}

option:checked,
select option:hover {
  color: #fff !important;
  background-color: #424550 !important;
}
/* .site-pages-outer{padding:72px 0 0;} */
.form-control::-webkit-input-placeholder {
  color: #fff;
}
.form-control::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-controlv::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #fff;
}
.form-control::-ms-input-placeholder {
  color: #fff;
}

.form-control::placeholder {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}
.theme-table-container {
  border-radius: 30px;
    overflow: hidden;
    background: #1B1D27;
    padding: 30px;
}
.table.theme-table {
  font-size: 16px;
}
.table.theme-table th {
  background-color: transparent !important;
  padding: 15px 0.5rem;
}
.table.theme-table td {
  background-color: transparent !important;
  padding: 15px 0.5rem;
}
.table.theme-table tr:last-of-type td {
   border: none;
}
.table.theme-table tr{vertical-align: middle;}
.table.theme-table .no-data-found {
  padding: 10px;
  background: #50fb56;
  color: #fff;
  border-radius: 4px;
}
.table.theme-table .badge {
  font-weight: normal;
  min-width: 70px;
  padding:5px;
  border-radius:4px;
  text-transform: capitalize;
}
.modal.theme-modal {
  z-index: 100000;
}
.modal.theme-modal .modal-dialog,
.modal.theme-modal .modal-content {
  background-color: transparent;
}
.modal.theme-modal .btn-close {
  width: 16px;
  height: 16px;
  position: absolute;
  padding: 0;
  top: 17px;
  right: 10px;
  margin: 0;
  transition: all ease-in-out 0.3s;
  background: none;
}
.modal.theme-modal .btn-close:before,
.modal.theme-modal .btn-close:after {
  width: 16px;
  height: 2px;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  transition: all ease-in-out 0.3s;
}
.modal.theme-modal .btn-close:before {
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal.theme-modal .btn-close:after {
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.modal.theme-modal .btn-close:hover:before,
.modal.theme-modal .btn-close:hover:after {
  background: #50fb56;
}
.modal.request-detail-image-modal .modal-header,
.modal.request-detail-image-modal .modal-footer {
  border: none;
}
.rc-pagination li {
  width: 40px;
  height: 40px;
  margin: 0 5px 5px 0;
}
.rc-pagination li button,
.rc-pagination li a {
  width: 100%;
  height: 100%;
  min-height: 100%;
  border: 1px solid #50fb56;
  border-radius: 4px;
  overflow: hidden;
  color: #50fb56;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  position: relative;
}
.rc-pagination li button[disabled],
.rc-pagination li a[disabled]{
  opacity: 0.5;
  cursor: not-allowed;
}
.rc-pagination li.rc-pagination-item-active a,
.rc-pagination li:hover button,
.rc-pagination li:hover a {
  border-color: #50fb56;
  background: #50fb56;
  color: #000 !important;
}
.rc-pagination li:hover button[disabled] {
  background: transparent;
}
.rc-pagination li.rc-pagination-prev button:after,
.rc-pagination li.rc-pagination-next button:after {
  content: "";
  width: 10px;
  height: 10px;
  border: solid #50fb56;
  border-width: 1px 0 0 1px;
  position: absolute;
  left: 50%;
  top: 50%;
}
.rc-pagination li.rc-pagination-prev button:after {
  -o-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  -moz-transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}
.rc-pagination li.rc-pagination-next button:after {
  -o-transform: translate(-50%, -50%) rotate(135deg);
  -ms-transform: translate(-50%, -50%) rotate(135deg);
  -moz-transform: translate(-50%, -50%) rotate(135deg);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.rc-pagination li.rc-pagination-prev:hover button[disabled]:after,
.rc-pagination li.rc-pagination-next:hover button[disabled]:after {
  border-color: #50fb56;
}
.rc-pagination li.rc-pagination-prev:hover button:after,
.rc-pagination li.rc-pagination-next:hover button:after {
  border-color: #fff;
}
.customAlert {
  background: #1f212f;
  color: #6e718b;
  border-color: #6e718b;
}

.theme {
  color: #50fb56;
}
.customBox-header {
  max-width: 1840px;
}
.customBox {
  max-width: 1722px;
}
section {
  overflow: hidden;
  /* padding: 120px 0px 0px 0px; */
}
a {
  font-size: 20px;
  line-height: 31px;
  font-weight: 400;
  text-decoration: none;
}
.ellipse {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.outfit-p {
  font-family: "outfit";
  font-size: 20px;
  line-height: 30px;
  opacity: 0.4;
}
.sofia-p {
  font-family: "SofiaPro";
  font-size: 24px;
  line-height: 36px;
  opacity: 0.69;
}
.danger-asterik {
  margin-left: 5px;
}
.link {
  position: relative;
  font-family: "outfit";
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  background: transparent;
  border: 0px;
  text-decoration: none;
  display: block;
  width: fit-content;
  text-decoration: none;
  border-radius: 0px;
  padding: 0px;
  min-height: auto;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
}
.link:hover {
  color: #fff;
}
.link.orangeLink {
  font-family: "outfit";
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  color: #50fb56;
}
.link.orangeLinkBtn {
  color: #50fb56;
  min-height: auto;
  min-width: auto;
}
.orangeLink.link::before,
.link.orangeLinkBtn::before {
  background: #50fb56;
}
.link.themeLink {
  color: #50fb56;
}
.link.themeLinkBtn {
  color: #50fb56;
}
.themeLink.link::before,
.link.themeLinkBtn::before {
  background: #50fb56;
}
/* .theme-form .outer-p.link:hover{
  color: #50fb56;
} */
.op6 {
  opacity: 0.6 !important;
}
.customSwitch .form-check-input:checked {
  background-color: #1f212f;
  border-color: #1f212f;
}
.customSwitch .form-check-input {
  background-color: #fe6c30;
  border: 1px solid #fff;
  width: 85px;
  height: 40px;
}
.customSwitch .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}
.blueLink {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
}
.link:hover:before {
  transform: scaleX(1);
}
.link::before {
  content: "";
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  background: #50fb56;
  transform: scaleX(0);
  transition: all ease-in-out 0.4s;
}
.cuetomModelBtn {
  background: transparent;
  border: 0px;
  padding: 0px;
  margin: 0px;
  text-align: inherit;
}
.theme-btn,
button {
  display: block;
  width: fit-content;
  font-family: "outfit";
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 10px;
  padding: 17px 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all ease-in-out 0.4s;
}
.btn-close {
  min-height: auto;
  height: auto;
}
.dbLogoutBtn {
  min-height: 48px;
  min-width: 110px !important;
}
.dbLogoutBtnMob {
  min-height: auto !important;
  min-width: auto !important;
}
.modal .theme-btn {
  min-width: 138px;
}
.modal .orange-btn {
  min-width: 126px;
}
.orange-btn {
  color: #50fb56;
  background: transparent;
  border: 2px solid #50fb56;
}
.orange-btn:hover {
  color: #000;
  background: #50fb56;
}
.outline-btn {
  color: #50fb56;
  background: transparent;
  margin-top: 80px;
  border: 1px solid #50fb56;
}
.outline-btn:hover {
  color: #50fb56;
  background: #f9b52b1a;
  border: 1px solid #50fb56;
}
.black-btn {
  color: #fff;
  background: #1e222f;
  min-width: 160px;
  border: 2px solid transparent;
}
.grey-btn {
  color: #fff;
  background: #343648;
  border: 2px solid transparent;
}
.grey-btn:hover {
  color: #fff;
  background: #00000036;
  border-color: #343648;
}
/* .black-btn:hover{
  color: #FC491E;
  background: transparent;
  border-color: #FC491E;
} */
.italicLink {
  font-family: "outfit";
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  opacity: 0.4;
  cursor: pointer;
  transition: all ease-in-out 0.4s;
}
.italicLink:hover {
  color: #fc491e;
  opacity: 1;
  text-decoration: underline;
}
.textBtn {
  font-family: "outfit";
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.5;
}
h1 {
  font-size: 46px;
  line-height: 52px;
  font-weight: 600;
  margin-bottom: 30px;
}
h2 {
  font-size: 60px;
  line-height: 76px;
  font-weight: 700;
  margin-bottom: 30px;
}
.H2 {
  font-size: 42px;
  line-height: 55px;
  font-weight: 600;
  margin-bottom: 0px;
}
.sofia-h2 {
  font-family: "SofiaPro";
  font-size: 60px;
  line-height: 94px;
  font-weight: 700;
  margin-bottom: 30px;
}
.sectionTitle {
  text-align: center;
  max-width: 68%;
  margin: 0 auto;
  z-index: 9999;
  position: relative;
}
.work_sectionTitle {
  max-width: 68%;
}
.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #50fb56;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.circle::before {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  margin: 5px;
  border-radius: 50%;
  background: #50fb56;
}
.iconDD-Btn,
.iconDD-Btn:active {
  padding: 0px;
  margin: 0px;
  background-color: transparent !important;
  border: 0px;
  font-size: inherit;
  min-height: auto;
  transition: all ease-in-out 0.4s;
}
.themeEffect {
  transition: all ease-in-out 0.4s;
}
.iconDD-Btn:hover,
.themeEffect:hover {
  color: #fc491e !important;
  cursor: pointer;
}
.iconDD-Btn::after {
  display: none;
}
.iconDD .dropdown-menu {
  padding: 0px;
  background: #222430;
  border-radius: 10px 0px 10px 10px;
  width: 218px;
  transform: translate(-200px, 45px) !important;
}
.iconDD .dropdown-item {
  font-family: "outfit";
  font-size: 14px;
  line-height: 35px;
  font-weight: 400;
  border-radius: 10px 0px 10px 10px;
  color: #fff;
  transition: all ease-in-out 0.4s;
}
.iconDD .dropdown-item span {
  font-size: 24px;
}
.barDD.iconDD .dropdown-menu {
  transform: translate(0px, 34px) !important;
}
.iconDD .dropdown-item:hover,
.iconDD .dropdown-item:active,
.iconDD .dropdown-item:focus {
  background: transparent;
  border: 0px;
  outline: none;
  color: #50fb56;
}

/* TABLE */
.themeTable {
  background: #151721 0% 0% no-repeat padding-box;
  border-radius: 20px;
  overflow-x: auto;
}
.themeTable table {
  color: #ffffff;
  padding: 0px 10px;
}
.themeTable table thead th p {
  font-family: "Outfit";
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
}
.themeTable table td p {
  font-family: "Outfit";
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
  opacity: 1;
}
.themeTable table th,
.themeTable table td {
  padding: 30px 30px 30px 30px;
  border-width: 0px;
  min-width: 100%;
}
.themeTable table .th-area,
.themeTable table .td-area {
  min-width: 150px;
}
.themeTable table .video-area {
  min-width: 250px;
}
.videoPara {
  max-width: 600px;
}
.themeTable table th.checkId,
.themeTable table td.checkId {
  min-width: auto;
}
.themeTable table tr:not(:last-child),
.themeTable table thead {
  border-bottom: 1px solid #6468662e;
}

/* TAB */
.tabFlex,
.subTabBrdr {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 0px solid #6468662e !important;
}
.tabFlex::after,
.subTabBrdr::after {
  content: "";
  height: 2px;
  width: 105%;
  position: absolute;
  right: 0;
  left: -5%;
  bottom: 0;
  border-bottom: 2px solid #6468662e;
}
.subTabPanel {
  padding-top: 0px !important;
}
.subTabPanel .themeTable {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.tabFlex > ul {
  padding: 0px 40px;
}
.tabFlex .realtimeBox {
  padding-bottom: 28px;
}
.react-tabs__tab-list {
  border-bottom: 0px solid #6468662e;
  margin-bottom: 0px !important;
}
.tabFlex h4 {
  font-family: "outfit";
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  opacity: 0.75;
}
/* .themeAnalytics .react-tabs__tab-list{padding: 0px 40px;} */
.wrapForm .nav-tabs {
  border-bottom: 0px;
  margin-bottom: 0px;
}
.tabFlex {
  padding: 0px 40px 0px 0px;
  margin-right: 40px;
}
.themeAnalytics .react-tabs__tab-list li:not(:last-child) {
  margin-right: 80px;
}
.themeAnalytics .react-tabs__tab-panel,
.themeAnalytics .react-tabs__tab-panel--selected {
  padding: 40px;
}
.themeAnalytics .react-tabs__tab,
.wrapForm .nav-tabs .nav-link {
  position: relative;
  font-family: "Outfit";
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  border: 0px;
  padding-bottom: 18px;
  background: transparent;
  color: #fff;
  opacity: 0.6;
  margin-right: 10px;
  transition: all ease-in-out 0.4s;
}
.themeAnalytics .tabFlex > ul li:not(:last-child) {
  margin-right: 40px;
}
.themeAnalytics .react-tabs__tab.react-tabs__tab--selected,
.wrapForm .nav-tabs .nav-link.active {
  color: #50fb56;
  opacity: 1;
}
.themeAnalytics .react-tabs__tab:hover:before,
.themeAnalytics .react-tabs__tab.react-tabs__tab--selected::before,
.wrapForm .react-tabs__tab:hover:before,
.wrapForm .nav-tabs .nav-link:hover:before,
.wrapForm .nav-tabs .nav-link.active::before {
  transform: scaleX(1);
}
.themeAnalytics .react-tabs__tab::before,
.wrapForm .nav-tabs .nav-link::before {
  content: "";
  display: block;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 30px;
  position: absolute;
  background: #50fb56;
  transform: scaleX(0);
  transition: all ease-in-out 0.4s;
}
.dashboard-sitePages p.md-P {
  font-family: "Outfit";
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #ffffff;
  opacity: 0.4;
}
.themeAnalytics .react-tabs__tab::after,
.wrapForm .nav-tabs .nav-link::after {
  display: none;
}
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}
input[type="checkbox"]:focus,
input[type="checkbox"]:checked {
  box-shadow: 0 0 0 0.1rem transparent;
  color: #50fb56;
}
.settingModel .checkboxText h4 {
  font-weight: 400;
}
.checkTable .form-check-input[type="checkbox"] {
  margin: 0px;
}
.form-check-input[type="date"],
.inputDiv input,
input[type="date"] {
  color: #50fb56;
}
.calendar-block {
  position: relative;
}
.theme-calendar {
  position: absolute;
  width: 100%;
}
::-webkit-calendar-picker-indicator {
  opacity: 0;
  font-size: 50px;
  cursor: pointer;
}
.inputDiv input.form-control:focus,
.inputDiv input.form-control:focus-visible {
  background: transparent !important;
}
.inputDiv.calendar-block svg {
  margin-left: auto;
  cursor: pointer;
  color: #50fb56;
}
.form-check.checkTable {
  margin-bottom: 0px;
  min-height: auto;
  padding-left: 0;
}
/* GRAPhH CSS */
.graphItems ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.graphItems ul li p {
  font-size: 20px;
  line-height: 48px;
  font-weight: 400;
  opacity: 0.6;
  margin-bottom: 0px;
}
.graphItems ul li:not(:last-child) {
  margin-right: 80px;
}
.graphItems ul li > div {
  display: flex;
  align-items: center;
}
.graphItems ul li > div::before {
  content: "";
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 2px;
  margin-right: 20px;
}
.graphItems ul li > .yellow::before {
  background-color: #f9b52b;
}
.graphItems ul li > .blue::before {
  background-color: #5490f2;
}
.graphItems ul li > .orange::before {
  background-color: #50fb56;
}
.custom-form-select select {
  background: #393c48;
  border-color: transparent;
  color: #fff;
}
.custom-form-select .form-select:focus {
  border-color: transparent;
}
.category-video-btn {
  min-width: unset !important;
  min-height: unset;
}
.custom-react-select .triage-select__control {
  background-color: #393c48 !important;
  border-color: transparent !important;
  color: #fff !important;
  min-width: 140px;
}
.custom-react-select .triage-select__menu {
  background-color: #393c48 !important;
}
.custom-react-select .triage-select__single-value {
  color: #fff !important;
}
.custom-react-select .triage-select__option--is-selected {
  background-color: #44454b !important;
}
.custom-react-select .triage-select__option--is-focused {
  background-color: #44454b !important;
}
.Toastify__toast-container--top-right {
  z-index: 999999 !important;
}
/**/
.swal2-popup {border-radius: 30px;overflow: hidden;background: #1B1D27;padding: 20px 0px;color: #fff;}
.swal2-popup .swal2-close {padding-bottom: 15px;}
.swal2-popup .swal2-actions .swal2-confirm {background-color: #50fb56 !important;}
.swal2-popup .swal2-actions button {color: #000 !important;}
/*react select*/
.react-select .react-select__control {color: #50fb56;background: transparent;border: 2px solid #50fb56;height: 60px;border-radius: 10px;box-shadow: none;}
.react-select .react-select__control:hover {border: 2px solid #50fb56;}
.react-select .react-select__single-value {color: #50fb56;font-size: 18px;line-height: 22px;font-weight: 700;}
.react-select input {color: #50fb56 !important;font-size: 18px !important;line-height: 22px !important;font-weight: 700 !important;}
.react-select .react-select__indicator-separator{background-color: #50fb56;}
.react-select .react-select__indicator{color: rgba(80, 251, 86, 0.8);}
.react-select .react-select__indicator:hover {color: #50fb56;}
.react-select .react-select__menu {background-color: #393c4b;}
.react-select .react-select__option {background-color: transparent;color: #fff;cursor: pointer;}
.react-select .react-select__option--is-selected {background-color: #50fb56;color: #1a1a1a;}
.react-select .react-select__option--is-selected.react-select__option--is-focused{background-color: #50fb56;color: #1a1a1a;}
.react-select .react-select__option:active, .react-select .react-select__option:hover {background-color: #50fb56;color: #1a1a1a;}
.react-select .react-select__option--is-focused {background-color: transparent;}
/*main page*/
@media (min-width: 1600px) {
  .main-layout .container {max-width: 1440px;}
}

/* MEDIA QUERIES */
@media (max-width: 1722px) {
  .customBox {
    max-width: 1600px;
  }
}
@media (max-width: 1600px) {
  .customBox {
    max-width: 1500px;
  }
}
@media (max-width: 1500px) {
  .customBox {
    max-width: 1400px;
  }
}
@media (max-width: 1400px) {
  .customBox {
    max-width: 1300px;
  }
}
@media (max-width: 1300px) {
  .customBox {
    max-width: 1200px;
  }
}
@media (max-width: 1200px) {
  .customBox {
    max-width: 1100px;
  }
}
@media (max-width: 1100px) {
  .customBox {
    max-width: 1000px;
  }
}
@media (max-width: 1000px) {
  .customBox {
    max-width: 900px;
  }
}
@media (max-width: 900px) {
  .customBox {
    max-width: 800px;
  }
}
@media (max-width: 800px) {
  .customBox {
    max-width: 650px;
  }
}
@media (max-width: 700px) {
  .customBox {
    max-width: 600px;
  }
}
@media (max-width: 600px) {
  .customBox {
    max-width: 500px;
  }
}
@media (max-width: 1699px) {
  .iconDD .dropdown-menu {
    border-radius: 8px 0px 8px 8px;
    width: auto;
    transform: translate(-150px, 34px) !important;
  }
  .iconDD .dropdown-item {
    font-size: 13px;
    line-height: 25px;
    border-radius: 8px 0px 8px 8px;
    padding: 8px 8px;
  }
  .themeAnalytics .react-tabs__tab-list {
    padding: 0px 20px;
  }
  /* TABLE */
  .themeTable {
    border-radius: 8px;
  }
  .themeTable table thead th p,
  .themeTable table td p {
    font-size: 14px;
    line-height: 15px;
  }
  .themeTable table th,
  .themeTable table td {
    padding: 25px 25px 25px 25px;
  }
  .dashboard-sitePages p.md-P {
    font-size: 13px;
    line-height: 20px;
  }
  .dashboard-sitePages .analyticDetailBox h4 {
    margin-bottom: 3px;
  }
  /* TAB */
  .themeAnalytics .react-tabs__tab-list {
    padding: 0px 20px;
  }
  .themeAnalytics .react-tabs__tab-list li:not(:last-child) {
    margin-right: 50px;
  }
  .themeAnalytics .react-tabs__tab-panel,
  .themeAnalytics .react-tabs__tab-panel--selected {
    padding: 20px;
  }
  .themeAnalytics .react-tabs__tab,
  .wrapForm .nav-tabs .nav-link {
    font-size: 17px;
    line-height: 20px;
    padding-bottom: 18px;
  }
  .themeAnalytics .react-tabs__tab::before,
  .wrapForm .nav-tabs .nav-link::before {
    height: 2px;
    border-radius: 20px;
  }
  .link.orangeLink,
  .tabFlex h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .tabFlex > ul {
    padding: 0px 20px;
  }
  .tabFlex .realtimeBox {
    padding-bottom: 20px;
  }
  .tabFlex::after {
    left: -3%;
  }
  .checkTable .form-check-input[type="checkbox"] {
    font-size: 13px;
  }
  .graphItems ul li p {
    font-size: 17px;
    line-height: 20px;
  }
  .graphItems ul li:not(:last-child) {
    margin-right: 40px;
  }
  .graphItems ul li > div::before {
    height: 2px;
    width: 13px;
    border-radius: 1px;
    margin-right: 10px;
  }
  .customSwitch .form-check-input {
    width: 50px;
    height: 25px;
  }
}
@media (max-width: 1520px) {
  /* section {
    padding: 60px 0px 0px 0px;
  } */
  a {
    font-size: 18px;
    line-height: 22px;
  }
  .outfit-p {
    font-size: 16px;
    line-height: 20px;
  }
  .sofia-p {
    font-size: 18px;
    line-height: 24px;
  }
  .blueLink {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
  }
  .theme-btn,
  button {
    font-size: 14px;
    line-height: 18px;
    border-radius: 5px;
    padding: 10px 25px;
    min-height: 45px;
  }
  .dbLogoutBtn {
    min-height: 35px;
    min-width: 90px !important;
  }
  .black-btn {
    min-width: 110px;
  }
  .modal .theme-btn {
    min-width: 90px;
  }
  .modal .orange-btn {
    min-width: 70px;
  }
  .outline-btn {
    margin-top: 50px;
  }
  .italicLink {
    font-size: 18px;
    line-height: 22px;
  }
  h1 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 18px;
  }
  h2,
  .sofia-h2 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 18px;
  }
  .H2 {
    font-size: 25px;
    line-height: 29px;
  }
  .circle {
    width: 18px;
    height: 18px;
  }
  .circle::before {
    margin: 4px;
  }
  .link {
    font-size: 12px;
    line-height: 18px;
  }
  .graphItems ul li > div::before {
    width: 10px;
    margin-right: 6px;
  }
  .graphItems ul li:not(:last-child) {
    margin-right: 17px;
  }
  .graphItems ul li p {
    font-size: 15px;
    line-height: 18px;
  }
  /**/
  .react-select .react-select__control {height: 45px;border-radius: 5px;}
  .react-select .react-select__single-value, .react-select input {font-size: 14px !important;line-height: 18px !important;}
  .react-select .react-select__option{font-size: 14px !important;line-height: 18px !important;}
}
@media (max-width: 1399px) {
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-track {
    width: 5px;
    height: 5px;
  }
  /* section {
    padding: 40px 0px 0px 0px;
  } */
  .theme-btn,
  button,
  .orange-btn,
  .outline-btn {
    border-width: 1px;
  }
  a,
  .italicLink {
    font-size: 16px;
    line-height: 20px;
  }
  .sofia-p {
    font-size: 16px;
    line-height: 24px;
  }
  .outfit-p {
    font-size: 14px;
    line-height: 19px;
  }
  .circle {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 896px) {
  .iconDD .dropdown-menu {
    transform: translate(-6px, 42px) !important;
  }
}
@media (max-width: 767px) {
  .tabFlex {
    flex-direction: column-reverse;
    padding: 10px;
    margin: 0px;
  }
  .tabFlex > ul,
  .themeAnalytics .react-tabs__tab-list {
    width: 100%;
  }
  .tabFlex > ul li,
  .themeAnalytics .react-tabs__tab-list li {
    margin-bottom: 10px;
  }
  .tabFlex .realtimeBox {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .tabFlex::after {
    left: -20px;
    width: 100%;
  }
  .themeAnalytics .react-tabs__tab {
    margin: 0px 10px 10px 10px;
  }
  .videoImg {
    width: 90px;
    height: 90px;
  }
  .videoImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .work_sectionTitle {
    max-width: 90%;
  }
  .sectionTitle {
    max-width: 90%;
  }
  h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 18px;
  }
}
@media (max-width: 575px) {
  /* section {
    padding: 60px 0px 0px 0px;
  } */
  .outfit-p {
    font-size: 14px;
    line-height: 19px;
  }
  .sofia-p {
    font-size: 16px;
    line-height: 22px;
  }
  h1,
  h2,
  .sofia-h2 {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 15px;
  }
  h1,
  h2 {
    line-height: 36px;
  }
  .H2 {
    font-size: 20px;
    line-height: 24px;
  }
}
.flipped {
  transform: scaleX(-1);
}
@media (max-width: 479px) {
  .formStyle {width: 100%;}
}
