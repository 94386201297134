.header{background: #151721; position: relative; width: 100%; top: 0; z-index: 99999;}
.header .navbar{padding: 19px 0px;}
.header .wrapHeaderBar{position: relative;width: 100%;}
.header .navbar-brand{padding: 0px;width: 100px;font-size:32px;line-height: 36px;}
.header .navbar-expand-lg .header .navbar-toggler{display: block;min-height: auto;}
.header .navbar-nav { flex-direction: row; justify-content: end; align-items: center; height: 100%; }
.header .navbar-collapse.collapse.show{height:100% !important;}
.header .navbar-collapse , .header .navbar-expand-lg .navbar-collapse{flex-basis: 100% !important; display: block !important; flex-grow: 1; position: absolute; top: 0px; bottom: 0; right: 60px; padding:0px 50px 0px 0px; background: #151721; width: 60%;}
.header .collapse:not(.show){position: absolute; top: auto; bottom: auto; right: -252px; width: 0; height: 0;}
.header .navbar-expand-lg .navbar-nav .nav-link {padding: 35px 0px; color: #fff; border-radius: 10px; font-size: 16px; line-height: 31px; font-weight: 500; text-transform: capitalize; transition: all ease-in-out .4s; }
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 70px;}
.header .navbar-expand-lg .navbar-nav .nav-link:hover{ color: #50fb56;}
.header .collapse:not(.show) { display: none !important;}
.header .dropdown-item.active, .header .dropdown-item:active { background-color: #50fb56;}
.header .navbar-nav .dropdown-menu{width: 300px;}
.header .navbar>.container-fluid{flex-direction: column;}
.header .nav-flex{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.header .navbar-toggler { padding: 0;font-size: 0; line-height: 0;border: 0px solid transparent; border-radius: 0;}
.header .navbar-toggler:focus{box-shadow: none;}
.header .nav-right-bar{display: flex;}
.header .nav-right-bar div:not(:last-child){margin-right:10px;}
.header .navBtns{display: flex; align-items: center; justify-content: center; width: 52px; height: 52px; background: #1F212F; border-radius: 10px; cursor: pointer;}
.header .navBtns svg{color: #6e718b; font-size: 20px;transition: all ease-in-out 0.3s;}
.header .navBtns:hover svg{color: #50fb56;transition: all ease-in-out 0.3s;}
.header .wrapSearch{display: flex; position: relative;}
.header .openSearch{position: absolute; right: 0; top:0; bottom:0; width: 550px; height: 52px; transition: 0.5s linear;}
.header .openSearch input{margin-bottom: 0px; height: 100% !important;}
.header .searchIcon svg{position: absolute; bottom: 0; top: 0; right: 10px; margin: auto; font-size: 20px; filter: brightness(0) invert(1); transition: all ease-in-out .4s; }
.header .searchIcon{cursor: pointer;}
.header .faBar {position: relative; height: 100%; width: 100%;     display: flex; flex-direction: column; justify-content: center; align-items: center;}
.header .faBar span{ display: block; width: 13px; height: 2px; background-color: #6e718b; margin: 2px 0px;transition: all .4s ease-in-out;}
.header .navBtns:hover .faBar span {background-color: #50fb56;transition: all ease-in-out 0.3s;}
.header .faCross span{position: absolute; right: 0; left: 0; top: 0; bottom: 0;margin: auto;transition: all .4s ease-in-out;}
.header .faCross span:nth-child(1){transform: rotate(-45deg);}
.header .faCross span:nth-child(2){visibility: hidden; display: none;}
.header .faCross span:nth-child(3){transform: rotate(45deg);}
/**/
.header .wrapHeaderBar .navbar-collapse.collapse.show ~ .wrapHeaderBar .nav-flex .faBar span {background-color: #50fb56;}
/* MEDIA QURIES */
@media (max-width:1699px) {
    .header .navbar{padding: 18px 0px;}
/* .navbar-brand{width: 180px;} */
.header .navBtns{ width: 40px; height: 40px; border-radius: 8px;}
.header .navbar-collapse , .navbar-expand-lg .navbar-collapse{ right: 40px; }
.header .nav-right-bar div:not(:last-child){margin-right:8px;}
.header .navBtns  svg{font-size: 14px;}
.header .navbar-expand-lg .navbar-nav .nav-link {padding: 15px 0px;}
}
@media (max-width:1399px) {
    .header .navBtns{ width: 35px; height: 35px; border-radius: 5px;}
    .header .nav-right-bar div:not(:last-child){margin-right:7px;}
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 50px;}
.header .navbar-expand-lg .navbar-nav .nav-link { padding: 8px 0px; font-size: 14px; line-height: 20px;}
.header .searchStyle, .header .openSearch{width: 450px;  height: 37px;}
.header .navbar-brand {width: 80px;}
}
@media (max-width:767px) {
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 35px;}
.header .navbar-collapse, .header .navbar-expand-lg .navbar-collapse{ padding: 0px 20px 0px 0px;}
}
@media (max-width:575px) {
.header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 20px;}
.header .navbar-collapse, .header .navbar-expand-lg .navbar-collapse{width: 40%;}
.header .searchStyle, .header .openSearch{width: 150px;}
} 
@media (max-width:479px) {
    .header .navbar-collapse, .header .navbar-expand-lg .navbar-collapse{position: unset;width: 100%;}
    .header .navbar-nav{flex-direction: column; justify-content: flex-start; align-items: center; padding-left: 10px;}
    .header .header .navbar-expand-lg .navbar-nav .nav-link:not(:last-child){margin-right: 0px;}
} 
@media (max-width:319px) {
    .header .nav-flex{flex-direction: column; justify-content: center;}
    .header .navbar-brand{margin: auto auto 10px auto; text-align: center;}
} 