.results {margin: 0 0 109px;}
.results .result-head {margin: 0 0 90px;}
.results .result-boxs {display: flex;align-items: center;flex-wrap: wrap;}
.results .box {background: #1C1B1B 0% 0% no-repeat padding-box;border-radius: 8px;padding: 47px 12px 20px;width: 18%;min-height: 240px;transform: translate3d(0px, 0px, 0px);transition: all ease-in-out 0.3s;}
.results .box:hover{transform: translate3d(0px, -5px, 0px);transition: all ease-in-out 0.3s;}
.results .box .box-image{width:65px;height: 65px;margin: 0 auto 30px;}
.results .box:not(:last-child){margin-right: 2%;}
/* responsive */
@media only screen and (max-width:1199px){
    .results .box {width: 23%;margin: 0 0 15px;}
}
@media only screen and (max-width:991px){
    .results .box {width: 31.33%;}
}
@media only screen and (max-width:767px){
    .results .box {width: 48%;}
}
@media only screen and (max-width:479px){
    .results .box {width: 100%;}
}