.banner {position: relative;background-image: url('../../../assets/images/banner-bg.svg');background-repeat: no-repeat;background-position: right;background-size: contain;padding: 280px 0px 320px;}
.banner h1 {margin: 0 0 17px;max-width: 400px;}
.banner .banner-img img{width: 100%;height: 100%;object-fit: contain;}
.banner .banner-img {position: relative;right: -55px;}
.banner .banner-data {padding-top: 60px;}
.banner .banner-lottie {display: block;max-width: 134px;}
/**/
.banner-note {margin-bottom: 82px;margin-top: -57px;position: relative;}
.banner-note .imp-note{padding: 57px 60px 25px;box-shadow: 0px 0px 16px #00000052;border: 2px solid #50FB56;border-radius: 16px;}
/* responsive */
@media only screen and (max-width:1799px){
    .banner {padding: 210px 0px 210px;}
}
@media only screen and (max-width:1399px){
    .banner .banner-data {padding-top: 0px;}
}
@media only screen and (max-width:1199px){
    .banner .banner-img {right: -30px;}
}
@media only screen and (max-width:991px){
    .banner {background-size: cover;}
}
@media only screen and (max-width:767px){
    .banner .banner-data { margin: 0 0 30px;}
    .banner {padding: 150px 0px 150px;}
    .banner-note .imp-note {padding: 30px 20px 25px;}
    .banner .banner-img {right: 0px;}
}
