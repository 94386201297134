.fullpage-loader-holder {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: rgb(13 15 23 / 80%);
}

.preloader {
    display: block;
    position: relative;
    width: 40px;
    height: 12px;
    margin: 10px auto;
    background: #50fb56;
    animation-name: loader;
    animation-duration: 10s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  .preloader::before, .preloader::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #50fb56;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
  .preloader::before {
    left: -10px;
    translate: -100% -50%;
    border-right-color: transparent;
    animation-name: loader-left;
  }
  .preloader::after {
    right: -10px;
    translate: 100% -50%;
    border-left-color: transparent;
    animation-name: loader-right;
  }
  
  @keyframes loader {
    0%, 10% {
      scale: 1;
    }
    15% {
      scale: 0.7 1.2;
    }
    40%, 60% {
      scale: 1;
    }
    65% {
      scale: 0.7 1.2;
    }
    100% {
      scale: 1;
    }
  }
  @keyframes loader-left {
    0% {
      translate: -400% -50%;
      scale: 1.3 0.6;
    }
    10%, 60% {
      translate: -100% -50%;
      scale: 1;
    }
    65%, 85% {
      translate: -300% -50%;
    }
    87.5% {
      translate: -310% -50%;
    }
    90% {
      translate: -290% -50%;
    }
    92.5% {
      translate: -310% -50%;
      scale: 1;
    }
    95% {
      translate: -290% -50%;
    }
    100% {
      translate: -400% -50%;
    }
  }
  @keyframes loader-right {
    0%, 10% {
      translate: 100% -50%;
      scale: 1;
    }
    15%, 35% {
      translate: 300% -50%;
    }
    15% {
      scale: 1.3 0.6;
    }
    20% {
      scale: 1;
    }
    37.5% {
      translate: 310% -50%;
    }
    40% {
      translate: 290% -50%;
    }
    42.5% {
      translate: 310% -50%;
    }
    45% {
      translate: 290% -50%;
      scale: 1;
    }
    50% {
      translate: 400% -50%;
      scale: 1.3 0.6;
    }
    60%, 100% {
      translate: 100% -50%;
      scale: 1;
    }
  }
  
.fullpage-loader-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
  }

  
  .text {
    display: block;
    padding: 30px;
    color: #50fb56;
    text-transform: uppercase;
    font-size: 50px;
    letter-spacing: 4px;
    font-weight: 800;
    line-height: 70px;
  }
 