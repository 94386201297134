.services-section h4 {margin: 0 0 13px;color: #50FB56;}
.services-section .services-data {max-width: 812px;margin: 0 0 0 auto;}
.services-section.biometric{margin: 0 0 250px;}
.services-section .services-image {max-width: 522px;}
.services-section.biometric .services-image {margin-right: 0px;margin-left: auto;}
.services-section.biometric .services-data {margin: 0 0 60px auto;}
/* responsive */
@media only screen and (max-width:1399px){
    .services-section.biometric {margin: 0 0 160px;}
}
@media only screen and (max-width:991px){
    .services-section.biometric .services-data {margin: 0 0 0px auto;}
}
@media only screen and (max-width:767px){
    .services-section.biometric {margin: 0 0 80px;}
}