.mainSidebar {position: fixed; top: 125px;  height: 80vh; z-index: 999; margin: 30px 0px 30px 34px;}
.sidebarFlex{display: flex; flex-direction: column; justify-content: space-between; min-height: 100%; height: 100%; overflow-y: auto; background: #151721; border-radius: 20px; padding-bottom: 40px; min-width: 294px;}
.sidebarFlex > .sidebarLink {height: 100vh; overflow-y: auto;}
.sidebarStyle.w2{min-width: 90px;}
.sidebarStyle.w2 ul{border-bottom: 0px; padding-top: 50px;}
.sidebarStyle ul li{margin-bottom: 10px;}
.sidebarStyle.w2 ul li a{ padding: 0px 0px 0px 13px; margin-right: 10px;}
.sidebarStyle.w2 ul li a span{padding-left: 5px; margin-right: 10px;}
.sidebarStyle.w2 ul li:not(:last-child) a span { border-bottom: 0px solid #303341; }
.sidebarStyle h3{ font-family: 'outfit';  font-size: 24px;  line-height: 30px;  font-weight: 700; padding: 30px 0px 30px 30px; color: #fff; text-transform: capitalize;}
.sidebarStyle ul{padding-left: 0px; margin-bottom: 0px; list-style: none;border-bottom: 1px solid #303341;}
.sidebarStyle ul li a{display: block; font-family: 'outfit';  font-size: 16px;  line-height: 25px;  font-weight: 500; padding: 0px 0px 0px 43px; color: #fff; text-transform: capitalize; text-decoration: none; margin-right: 29px; border-radius: 0px 25px 25px 0px; transition: all ease-in-out .4s;}
.sidebarStyle ul li a span { display: inline-block; padding: 15px 0px 15px 0px; width: 100%; padding-left: 15px;}
.sidebarStyle ul li:not(:last-child) a span { border-bottom: 1px solid #303341; }
.sidebarStyle ul li a:hover, .sidebarStyle ul li a.active{background: #FD6F21;}
.sidebarStyle ul li a:hover span, .sidebarStyle ul li a.active span{border-color: transparent;}
.sidebarStyle .orange-btn{margin-left:43px;}
.sidebarLogo{float: right; font-size: 22px; color: #fff; cursor: pointer; margin: -20px; width: 65px; height: 65px; border-radius: 50%; background: #FD6F21; display: flex; align-items: center; justify-content: center; transition: all ease-in-out .4s; transition-delay: .2s;}
.rotate{transform: rotate(-135deg);}
.logo-change{transform: rotate(90deg);}
.mainSidebar .orange-btn{transition: all ease-in-out .2s;}
.logout{background-color: transparent; border:0px; transition: all ease-in-out 0s;}
.logout svg{ font-size: 35px; color: #fff;}
.sitePages-main-width{
    /* height: calc(100vh - 90px); */
    padding: 0px 15px 0;}
.logout svg:hover{color: #50fb56;}
.dashboard-box{height:100vh}
.dashboard-box .dashboard-block {padding: 50px 30px;border-radius: 30px;background: #1B1D27;}
.dashboard-box .dashboard-block p {margin: 0 0 60px;}
/**/
.dashboard-box .video-holder {width: 360px;height: 360px;padding: 20px;position: relative;border-radius: 50%;margin: 0 auto;}
.dashboard-box .video-holder video {width: 100%;display: block;height: 100%;object-fit: cover;border-radius: 50%;}
.dashboard-box .rectangle {position: absolute;width: 15px; height: 8px; background-color: #fff; transform-origin: center; border-radius: 0px; transition: all ease-in-out 0.3s;}
.dashboard-box .rectangle.green {width: 25px;background-color: lawngreen;transition: all ease-in-out 0.3s;}
/**/
.dashboard-box.capture-image .video-holder {width: 480px;border-radius: 10px;}
.dashboard-box.capture-image .video-holder video {border-radius: 10px;}
/**/
.dashboard-box .face-area {width: 330px;height: 330px;margin: 0 0 30px;position: relative;}
.dashboard-box .face-area .face {position: absolute;left: 50%;top: 50%;width: 100px;height: 100px;transform: translate(-50%, -50%);animation: watchAnimation 5s infinite;}
@keyframes watchAnimation {
    0% {transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg);}
    20% {transform: translate(-50%, -50%) rotateX(-30deg) rotateY(0deg);}
    40% {transform: translate(-50%, -50%) rotateX(0deg) rotateY(30deg);}
    60% {transform: translate(-50%, -50%) rotateX(0deg) rotateY(-30deg);}
    80% {transform: translate(-50%, -50%) rotateX(30deg) rotateY(0deg);}
    100% {transform: translate(-50%, -50%) rotateX(0deg) rotateY(0deg);}
}
.dashboard-box .face-success{position: absolute;left: 50%;top: 50%;width: 250px;height: 250px;border-radius: 50%;overflow: hidden;transform: translate(-50%, -50%);}
.dashboard-box .face-success img {width: 100%;height: 100%;object-fit: cover;filter: blur(25px);-webkit-filter: blur(25px);}
.dashboard-block .try-buttons {margin-top: 40px;}
/**/
.dashboard-box .input-file-btn-holder{width:260px;height:72px;position: relative;}
.dashboard-box .input-file-btn-holder input[type="file"]{opacity: 0;font-size:0;background:transparent;outline:none;border:none;width:100%;height:100%;position: absolute;left:0;top:0;z-index:1;cursor:pointer;}
.dashboard-box .input-file-btn-holder .orange-btn{width:100%;height:100%;border-radius:10px;font-weight: bold;}
.dashboard-box .captured-images .captured-image{width:250px;padding:20px 15px;margin:0 10px 15px;border:1px solid #50fb56;border-radius: 10px;}
.dashboard-box .captured-images .captured-image .image-holder{border-radius:10px;overflow:hidden;width: 218px;height: 130px;}
.dashboard-box .captured-images .captured-image .image-holder img {width: 100%;height: 100%;object-fit: contain;}
.uploaded-card-info li{width:50%;}
.request-item{border-radius: 30px;overflow: hidden;background: #1B1D27;padding: 30px;}
.request-item .uploaded-image-holder{width:293px;height:195px;border-radius:10px;overflow: hidden;}
.request-item .uploaded-image-holder img{width:100%;height:100%;object-fit: cover;}
.requests-table .image-holder{width:40px;height:40px;border-radius:5px;overflow:hidden;box-shadow:0 0 5px rgba(0,0,0,0.4);}
.requests-table .image-holder img{width:100%;height:100%;object-fit: cover;}
.requests-table .action-list{font-size:14px;}
.requests-table .action-list li{width:40px;height:40px;border-radius: 4px;}
/**/
.requests-table .action-list li button {transition: all ease-in-out 0.3s;width: 40px;height: 40px;padding: 0;min-height: auto;border: none;font-size: 14px;line-height: 18px;border-radius: 4px;}
.requests-table .action-list li .eye-button{background:  transparent;color:  #0BA1FF;border:1px solid  #0BA1FF;}
.requests-table .action-list li .eye-button:hover{background:  #0BA1FF;color: #000;}
.requests-table .action-list li .check-button{background: transparent;color: #50fb56;border:1px solid #50fb56;}
.requests-table .action-list li .check-button:hover{background: #50fb56;color: #000;}
.requests-table .action-list li .delete-button{background: transparent;color: #ff0404;border:1px solid #ff0404;}
.requests-table .action-list li .delete-button:hover{background: #ff0404;color: #000;}
/**/
.modal-captured-image-holder img{width:100%;height:auto;}
/**/
.capture-image{padding: 50px 0 30px;}
.capture-image .dashboard-block {max-width: 1600px;margin: 0 auto;}
.capture-image .image-uploader-box {width: 460px;border-radius: 10px;margin: 0 0 0 30px;background: #0d0f17;min-height: 300px;}
.capture-image .instructions-block {flex: 1;}
.capture-image .cnic-images {align-items: center;}
/**/
.video-recording {padding: 50px 0 30px;}
/**/
.capture-image .reset-actions button{min-width: 190px;}
/**/
.dashboard-page {margin: 100px 0px;}
/**/
.bg-none{background: transparent !important;}
/**/
.video-recording .dashboard-block {max-width: 700px;margin: 0 auto;}
.video-recording h1 {font-size: 50px;line-height: 70px;font-weight: bold;}
/*media qureies*/
@media (max-width:1699px) {
.mainSidebar{top: 91px;margin: 25px 0px 25px 30px;}
.explore{padding-top: 105px;}
.sidebarStyle h3{ font-size: 18px; line-height: 24px; padding: 20px 0px 20px 20px;}
.sidebarStyle ul li a { font-size: 14px; line-height: 20px; padding: 0px 0px 0px 15px;}
.sidebarStyle ul li a span { padding: 11px 0px 11px 0px; padding-left: 13px;}
.sidebarStyle ul li a img{width: 15px;}
.sidebarLogo{width: 45px; height:45px;}
.sidebarLogo svg{width: 15px;}
.sidebarFlex {min-width: 220px;}
.memberImg img{max-width: 150px;}
.sidebarStyle .orange-btn{margin-left: 15px;}
}
@media (max-width:1520px) {
    .dashboard-box .input-file-btn-holder .orange-btn {font-size: 14px;line-height: 18px;}
    .dashboard-box .input-file-btn-holder {height: 60px;}
    .capture-image .reset-actions button {min-width: 164px;}
}
@media (max-width:1399px) {
.sidebarStyle h3{ font-size: 16px; line-height: 20px; padding: 15px 0px 15px 15px;}
.sidebarLogo{width: 35px; height:35px;}
.sidebarLogo svg{width: 10px;}
.sitePages { padding: 20px 20px 35px 20px;}
.sidebarStyle.w2{min-width: 50px;}
.logout svg { font-size: 20px;}
.sidebarStyle .orange-btn{margin-top: 20px;}
.request-item .uploaded-image-holder{width:247px;height:180px;}
/**/
.capture-image .image-uploader-box {width: 350px;}
/**/
.dashboard-box .face-area{width: 270px;height: 270px;}
.dashboard-box .face-success {width: 210px;height: 210px;}
.dashboard-box .video-holder {width: 280px;height: 280px;}
.dashboard-box.capture-image .video-holder {width: 400px;}
.dashboard-box .rectangle {height: 5px;}
}
@media (max-width:1199px){
    .request-item .uploaded-image-holder{width:200px;height:145px;}
}
@media (max-width:992px) {
.sidebarFlex{width: 0px;}
.sidebarStyle.w2 ul li a { padding: 0px 0px 0px 7px;}
 }
@media (max-width:991px){
    .request-item .uploaded-image-holder{width:100%;height:auto;margin:0 0 15px;}
    /**/
    .capture-image .cnic-images{flex-direction: column;}
    .capture-image .image-uploader-box {width: 100%;margin: 0;padding: 50px;}
}
@media (max-width:767px){
    /* .dashboard-box .video-holder{width:500px;} */
    .video-recording h1 {font-size: 40px;line-height: 60px;}
 }
@media (max-width:575px){
    .dashboard-box .dashboard-block{padding:30px 15px;}
    /* .dashboard-box .video-holder{width:390px;} */
    /**/
    .dashboard-box .face-area {width: 220px;height: 220px;}
    .dashboard-box .face-area .face {width: 80px;}
    .dashboard-box .face-success {width: 170px;height: 170px;}
    .dashboard-box .video-holder { width: 230px; height: 230px;}
    .dashboard-box .dashboard-block p {margin: 0 0 40px;text-align: center;}
    /**/
    .dashboard-block .captured-images { flex-direction: column;}
    .video-recording h1 {font-size: 38px;line-height: 58px;}
}
@media (max-width:479px){
    /* .dashboard-box .video-holder{width:280px;} */
    .dashboard-block h1{line-height: 35px;font-size: 26px;text-align: center;}
    .dashboard-box .input-file-btn-holder {width: 204px;}
    .captured-images, .reset-actions {flex-direction: column;align-items: center;}
    .dashboard-box.capture-image .video-holder {width: 100%;padding: 0;}s
    /**/
    .capture-image .image-uploader-box {padding: 50px 12px;}
}
 @media (max-width:467px) {
.mainSidebar {top: 80px; height: 70vh;}
 }
 @media (max-width:319px) {
.mainSidebar {top: 150px; }
.dashboard-box .captured-images .captured-image {width: 210px;}
.dashboard-box .captured-images .captured-image .image-holder {width: 178px;height: 110px;}
.dashboard-box .input-file-btn-holder {width: 180px;}
 }