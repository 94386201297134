.verfication {margin: 0 0 72px;}
.verfication .verification-box {padding: 96px 90px 103px;background: #1C1B1B 0% 0% no-repeat padding-box;border-radius: 16px;}
.verfication .verification-box h4{margin: 0 0 53px;text-align: center;}
.verfication .verification-split {display: flex;justify-content: space-between;align-items: center;}
.verfication .verfication-lottie {width: 40%;max-width: 343px;height: 343px;}
.verfication .verfication-faqs {flex: 1;max-width: 702px;}
.verfication .accordion-item {border: 1px solid #50FB56;border-radius: 16px;background-color: transparent;color: #fff;}
.verfication .accordion-item:not(:last-child) {margin: 0 0 27px;}
.verfication .accordion-item:first-of-type {border-top-left-radius: 16px;border-top-right-radius: 16px;}
.verfication .accordion-item:last-of-type {border-bottom-right-radius: 16px;border-bottom-left-radius: 16px;}
.verfication .accordion-item:first-of-type .accordion-button {border-top-left-radius: 16px;border-top-right-radius: 16px;}
.verfication .accordion-button {background-color: transparent;box-shadow: none;border: none;color: #fff;font-size: 32px;line-height: 40px;font-weight: bold;}
.verfication .accordion-button::after{background-image: url("../../../assets/images/accordion-icon.png");}
.verfication .accordion-body {font-size: 16px;line-height: 20px;font-weight: normal;}
/* responsive */
@media only screen and (max-width:1599px){
    .verfication .accordion-button {font-size: 26px;line-height: 34px;}
}
@media only screen and (max-width:1399px){
    .verfication .accordion-button {font-size: 24px;line-height: 32px;}
}
@media only screen and (max-width:1199px){
    .verfication .verification-box {padding: 90px 30px 90px;}
}
@media only screen and (max-width:767px){
    .verfication .verification-split {flex-direction: column;}
}