.profile-sec .table-big-boy {background: #303341;}
.card-header-title {border-bottom: 0;}
.profile-sec .input-file-btn-holder {
    position: relative;
    width: 146px;
    height: 50px;
    margin: 0 auto 50px;
    border-radius: 10px;
    overflow: hidden;
}
.profile-sec .input-file-btn-holder input {
    width: 100%;
    height: 100%;
    display: block;
    font-size: 0;
    opacity: 0;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.profile-sec .input-file-btn-holder .input-file-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #FC491E;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.profile-sec .upload-img .author img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
}