.terms-sec {padding: 160px 0px;box-shadow: 0px 3px 16px #0000000f;background-color: #1c1b1b;margin-top: 74px;}
.terms-sec h1 {margin-bottom: 20px;}
.terms-sec p {max-width: 900px;}
.details-sec {padding: 100px 0;}
.details-sec p {margin-bottom: 60px;}
.details-sec .wrapper {background-color: #1c1b1b;border-radius: 10px;padding: 30px;display: flex;max-width: 100%;margin: 0 auto 68px;min-height: 340px;}
.details-sec .wrapper .img {margin-right: 40px;}
.details-sec .wrapper .H2 {font-weight: 500;margin-bottom: 15px;line-height: 42px;}
.details-sec .wrapper .content {width: calc(100% - 70px);}
.details-sec .wrapper p {font-size: 16px;font-weight: 400;margin: 0 0 20px;}
.details-sec .wrapper ul li {font-size: 16px;font-weight: 400;line-height: 20px;margin: 0 0 14px;}
.details-sec .wrapper ul {list-style: square;margin: 0;border-left: 2px solid #50fb56;list-style: none;}
.details-sec .wrapper ul li::marker{color: #50fb56;}
.details-sec .all-others h1 {margin-bottom: 80px;}
.details-sec .all-others-content h4 {color: #50FB56;margin-bottom: 20px;}
.details-sec .all-others-content p {margin-bottom: 60px;}
.details-sec .all-others-content p.not-last {margin-bottom: 15px;}
/**/
.details-sec .custom-p {max-width: 1494px;}

/*responsivesness*/
@media (max-width: 1699px){
   
}
@media (max-width: 1599px) {
    .terms-sec {padding: 100px 0;}
    .details-sec {padding: 80px 0;}
    .details-sec p {margin-bottom: 40px;}
    .details-sec .wrapper {padding: 20px;}
    .details-sec .all-others h1 {margin-bottom: 50px;}
    .details-sec .all-others-content h4 {margin-bottom: 10px;}
    .details-sec .all-others-content p {margin-bottom: 40px;}
    .details-sec .wrapper {margin: 0 auto 40px;}
}
@media (max-width: 1399px) {
    .terms-sec,
    .details-sec {padding: 70px 0;}
    .details-sec .wrapper .H2 {margin-bottom: 5px;}
    .details-sec .wrapper p {line-height: 24px;}
    .details-sec .all-others h1 {margin-bottom: 40px;}
}
@media (max-width: 1199px) {
    .details-sec .wrapper .img {margin-right: 20px;}
    .details-sec .wrapper ul {padding-left: 15px;}
    .details-sec .wrapper {min-height: auto;}
}
@media (max-width: 991px) {
    .terms-sec, .details-sec {padding: 50px 0;}
}
@media (max-width: 575px) {
    .terms-sec, .details-sec {padding: 30px 0;}
    .details-sec p {margin-bottom: 30px;}
    .details-sec .wrapper {margin: 0 auto 30px;}
    .details-sec .all-others h1 {margin-bottom: 20px;}
    .details-sec .all-others-content p {margin-bottom: 30px;}
    .details-sec p {font-size: 16px;line-height: 26px;}
    .details-sec .ul-list ul {padding-left: 15px;}
    .details-sec .ul-list ul li {font-size: 16px;line-height: 24px;}
    .details-sec .wrapper .H2 {line-height: 26px;}
}
@media (max-width: 467px) {
    .details-sec .wrapper{flex-wrap: wrap;}
    .details-sec .wrapper > div {margin-bottom: 10px;}
    .details-sec .wrapper .content{width: 100%;}
}