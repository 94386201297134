/**/
.main-button{border: 1px solid #50FA56;border-radius: 8px;font-size: 16px;line-height: 20px;font-weight: 600;color: #50FA56;background: transparent;padding: 12px 15px;transition: all ease-in-out 0.3s;}
.main-button:hover{background: #50FB56 0% 0% no-repeat padding-box;transition: all ease-in-out 0.3s;color: #000;}
/**/
.black-button{border: 1px solid #000;border-radius: 8px;font-size: 16px;line-height: 20px;font-weight: 600;color: #000;background: transparent;padding: 17px 61px;max-height: 55px;transition: all ease-in-out 0.3s;}
.black-button:hover{background: #000 0% 0% no-repeat padding-box;color: #fff;transition: all ease-in-out 0.3s;}
/**/
strong{color: #50FB56;}
h1{font-size: 64px;line-height: 81px;font-weight: bold;}
p{font-size: 24px;line-height: 30px;font-weight: normal;}
h3{font-size: 24px;line-height: 30px;font-weight: 500;}
h4{font-size: 40px;line-height: 50px;font-weight: bold;}
h5{font-size: 17px;line-height: 23px;font-weight: 500;}
a {display: inline-block;}
/* responsive */
@media only screen and (max-width:1799px){
    h1 {font-size: 54px;line-height: 74px;}
    p {font-size: 22px;line-height: 28px;}
    h4 {font-size: 36px;line-height: 46px;}
}
@media only screen and (max-width:1599px){
    h1 {font-size: 50px;line-height: 68px;}
    p {font-size: 20px;line-height: 26px;}
    h4 {font-size: 32px;line-height: 42px;}
    h3 {font-size: 22px;line-height: 28px;}
    h5 {font-size: 15px;line-height: 20px;}
}
@media only screen and (max-width:1399px){
    h1 {font-size: 45px;line-height: 63px;}
    p {font-size: 17px;line-height: 23px;}
    h4 {font-size: 28px;line-height: 38px;}
    .main-button {font-size: 14px;line-height: 18px;}
    .black-button {font-size: 14px;line-height: 18px;padding: 17px 40px;}
}
@media only screen and (max-width:1199px){
    h5 {font-size: 14px;line-height: 20px;}
}